import { without } from 'lodash';
import Default from '../../../common/Themes/Default/Theme';
import { sectionHeadingAlignment, sectionHeadingHR } from '../../../common/utils/themeOverrides';
import { sectionHrTypes, WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import { getMenuHeadingStyle } from '../../../common/utils/legacyOverrides';
import * as modernIcons from '../../../common/IconPacks/modernThinRound';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Balance } from '../../../common/loaders';
import themeConfig from '../themeConfig';

const { INLINE } = sectionHrTypes;
const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highlight',
    fontSize: 'xlarge',
    fontWeight: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

class Theme14 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme14';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        useSecondBackground: true,
        usePhone: true,
        phoneOnSecondaryPage: true,
        showShrinkFit: true,
        headerTreatmentsConfig: {},
        coverImagePivot: {},
        hasLogoAlign: true
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  static excludedProps = without(Default.excludedProps, 'background2');

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      spacingSmall: '12px',
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            fontSize: 'xxlarge',
            color: 'highlight',
            lineHeight: 1.27,
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          ...typographyShared1
        },
        BodyGamma: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'mlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: '400',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        }
      }
    };
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...modernIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Balance.apply(this, [props]);
  }

  CloseIcon(props) {
    return super.CloseIcon(
      this.merge(
        {
          style: {
            lineHeight: '1.3em'
          }
        },
        props
      )
    );
  }

  Heading(props) {
    const { tag } = props;
    const { widgetType, widgetPreset } = this.base;

    return super.Heading(
      this.merge(
        {
          style: getMenuHeadingStyle(tag, widgetType, widgetPreset)
        },
        props
      )
    );
  }

  SectionHeading(props) {
    const { layout = 'full' } = props;
    const overrides = this.merge(sectionHeadingAlignment(this.base), sectionHeadingHR(this.base));
    return super.SectionHeading(
      this.merge(
        {
          style: {
            overflow: 'hidden',
            textAlign: 'center',
            ['@md']: {
              textAlign: layout === 'full' ? 'center' : 'left'
            }
          },
          sectionHeadingHR: INLINE,
          typography: 'HeadingGamma'
        },
        overrides,
        props
      )
    );
  }

  Tagline({ hasElementBelow, ...props }) {
    return super.Tagline(
      this.merge(
        {
          style: {
            maxWidth: '100%',
            marginBottom: hasElementBelow ? 'xsmall' : '0'
          },
          typography: 'HeadingBeta'
        },
        props
      )
    );
  }

  HeroText(props) {
    return super.SubTagline(
      this.merge(
        {
          style: {
            maxWidth: '100%'
          },
          fontSizeMap: {
            large: [0, 50],
            medium: [51, 110],
            small: [111],
            ['@xs-only']: {
              large: [0, 35],
              medium: [36]
            }
          },
          featured: false
        },
        props
      )
    );
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            backgroundColor: 'sectionLowContrast'
          }
        },
        props
      )
    );
  }

  DisplayHeading(props) {
    return super.DisplayHeading(
      this.merge(
        {
          typography: 'HeadingGamma'
        },
        props
      )
    );
  }

  FooterDetails(props) {
    return super.FooterDetails(
      this.merge(
        {
          typography: 'DetailsBeta'
        },
        props
      )
    );
  }

  PromoBannerText(props) {
    return super.PromoBannerText(this.merge({ featured: true }, props));
  }

  Phone(props) {
    return super.Phone(
      this.merge(
        {
          typography: 'BodyGamma'
        },
        props
      )
    );
  }

  Input(props) {
    return super.Input(
      this.merge(
        {
          style: {
            borderColor: 'input',
            borderRadius: 'medium',
            borderWidth: 'xsmall',
            paddingVertical: 'xsmall',
            paddingHorizontal: 'xsmall',
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(
        {
          style: {
            left: '11px',
            top: '35%'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          style: {
            borderColor: 'input',
            borderRadius: 'medium',
            borderWidth: 'xsmall',
            borderStyle: 'solid',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingVertical: 'xsmall',
            paddingHorizontal: 'xsmall',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  MediaObject(props) {
    return super.MediaObject(
      this.merge(
        {
          style: {
            alignItems: 'center'
          }
        },
        props
      )
    );
  }

  BlogAside(props) {
    return super.BlogAside(
      this.merge(
        {
          section: 'alt',
          style: {
            height: 'auto',
            backgroundColor: 'section',
            paddingVertical: 'large',
            paddingHorizontal: 'large',
            borderTopWidth: '0',
            ['@md']: {
              paddingVertical: 'large',
              borderWidth: '0'
            }
          }
        },
        props
      )
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  ContentCard(props) {
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(styles, props));
  }

  NavigationDrawer(props) {
    return super.NavigationDrawer(
      this.merge(props, {
        category: 'accent'
      })
    );
  }
}

export default Theme14;
